/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import {configureFonts, getFontThemeList} from '@nfq/next-fonts';
import {createGlobalStyle} from 'styled-components';

import type {Config} from '@nfq/react-grid';
import type {DefaultTheme} from 'styled-components';

export const fontDefinitions = configureFonts({
    OnAir: [
        {
            fontDisplay: 'swap',
            fontStyle: 'italic',
            fontWeight: 'bold',
            preload: false,
            src: '/fonts/OnAir-BoldItalic.woff2'
        },
        {
            fontDisplay: 'swap',
            fontStyle: 'normal',
            fontWeight: 'bold',
            preload: true,
            src: '/fonts/OnAir-Bold.woff2'
        },
        {
            fontDisplay: 'swap',
            fontStyle: 'italic',
            fontWeight: 'normal',
            preload: false,
            src: '/fonts/OnAir-Italic.woff2'
        },
        {
            fontDisplay: 'swap',
            fontStyle: 'normal',
            fontWeight: 'normal',
            preload: true,
            src: '/fonts/OnAir-Regular.woff2'
        }
    ]
});

const nfqgrid: Config = {
    baseSpacing: 0.4,
    container: {
        lg: 'fluid',
        md: 'fluid',
        sm: 'fluid',
        xl: 'fluid',
        xs: 'fluid',
        xxl: 1920
    },
    containerPadding: {
        lg: 80,
        md: 80,
        sm: 80,
        xl: 80,
        xs: 80,
        xxl: 80
    }
};

export enum Colors {
    /** Approved Color. ![#74CF9A](https://via.placeholder.com/12/74CF9A/74CF9A.png) `#74CF9A`. */
    approvedColor = '#74CF9A',
    /** Focus border color for buttons. ![#41B6E6](https://via.placeholder.com/12/41B6E6/41B6E6.png) `#41B6E6`. */
    buttonBorderColor = '#41B6E6',
    /** Border color for input. ![#E5EAEE](https://via.placeholder.com/12/E5EAEE/E5EAEE.png) `#E5EAEE`. */
    inputBorderColor = '#E5EAEE',
    /** Error Color. ![#F65D7C](https://via.placeholder.com/12/F65D7C/F65D7C.png) `#F65D7C`. */
    errorColor = '#F65D7C',
    /** Success Color. ![#74CF9A](https://via.placeholder.com/12/74CF9A/74CF9A.png) `#74CF9A`. */
    successColor = '#74CF9A',
    /** Page background color. ![#F3F5F6](https://via.placeholder.com/12/F3F5F6/F3F5F6.png) `#F3F5F6`. */
    pageBackground = '#F3F5F6',
    /** Pending Color. ![#39C7C5](https://via.placeholder.com/12/39C7C5/39C7C5.png) `#39C7C5`. */
    pendingColor = '#39C7C5',
    /** Popover background color. ![#FFFFFF](https://via.placeholder.com/12/FFFFFF/FFFFFF.png) `#FFFFFF`. */
    popoverBackgroundColor = '#FFFFFF',
    /** Primary background color. ![#000066](https://via.placeholder.com/12/000066/000066.png) `#000066`. */
    primaryBackgroundColor = '#000066',
    /** Primary action color. ![#0019A5](https://via.placeholder.com/12/0019A5/0019A5.png) `#0019A5`. */
    primaryActionColor = '#0019A5',
    /** Primary font color. ![#192630](https://via.placeholder.com/12/192630/192630.png) `#192630`. */
    primaryFontColor = '#192630',
    /** Rejected Color. ![#F65D7C](https://via.placeholder.com/12/F65D7C/F65D7C.png) `#F65D7C`. */
    rejectedColor = '#F65D7C',
    /** Secondary font color. ![#7A8894](https://via.placeholder.com/12/7A8894/7A8894.png) `#7A8894`. */
    secondaryFontColor = '#7A8894',
    /** Ultra light font color. ![#CCD5DD](https://via.placeholder.com/12/CCD5DD/CCD5DD.png) `#CCD5DD`. */
    ultraLightFontColor = '#CCD5DD',
    /** Withdrawn Color. ![#B1E4E3](https://via.placeholder.com/12/B1E4E3/B1E4E3.png) `#B1E4E3`. */
    withdrawnColor = '#B1E4E3'
}

export enum BoxShadows {
    /** The default dropshadow for Popovers. */
    popOverShadow = '0px 0px 10px 5px rgba(25, 38, 48, 0.05)'
}

export const theme: DefaultTheme = {
    boxShadows: BoxShadows,
    colors: Colors,
    fonts: getFontThemeList(fontDefinitions),
    nfqgrid
};

export const GlobalStyle = createGlobalStyle`
    *,
    &::before,
    &::after {
        box-sizing: border-box;
    }

    * {
        -webkit-tap-highlight-color: transparent;
        -webkit-tap-highlight-color: transparent;
    }

    html {
        font-size: 10px;
    }

    html, body {
        background-color: ${({theme: globalTheme}) => globalTheme.colors.pageBackground};
        color: ${({theme: globalTheme}) => globalTheme.colors.primaryFontColor};
        margin: 0;
        min-height: 100%;
        padding: 0;
        scroll-behavior: smooth;
    }

    #__next {
        background-color: ${({theme: globalTheme}) => globalTheme.colors.pageBackground};
        min-height: 100%;
    }
`;