import type {Variants} from 'framer-motion';

export const LayoutTransition: Variants = {
    enter: {transition: {when: 'beforeChildren'}},
    exit: {transition: {when: 'afterChildren'}},
    initial: {}
};

export const DetailLayoutTransition: Variants = {
    enter: {
        opacity: 1,
        transition: {when: 'beforeChildren'}
    },
    exit: {
        opacity: 0,
        transition: {when: 'afterChildren'}
    },
    initial: {opacity: 0}
};

export const FooterAnimation: Variants = {
    enter: {
        opacity: 1,
        transition: {duration: 0.4},
        y: '0%'
    },
    exit: {
        opacity: 0,
        transition: {duration: 0.4},
        y: '100%'
    },
    initial: {
        opacity: 0,
        y: '100%'
    }
};